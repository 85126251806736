<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data 
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute || load">Сохранить</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering"></td>
                </template>

                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAll" 
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            title="Удалить выбранные записи" 
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`Очистить ${selectAll ? 'все' : 'выбранные'} записи?`)"/>
                    </div>
                </template>

                <template #cell(action)="data">
                    <b-form-checkbox 
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(name_ru)="data">
                    <div v-if="data.item.par_id==null">{{ data.item.name_ru }}</div>
                    <div v-else >{{'  -  ' + data.item.name_ru }}</div>
                </template>
                <template #cell(medp_count)="data">
                    <b-form-input v-if="!data.item.hasChild && variantAttribute"
                                  class="text-right"
                                  :value="data.item.medp_count"
                                  @change="v => data.item.medp_count = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'medp_count', data.item.medp_count, 0)">
                    </b-form-input>
                    <div v-else-if="!data.item.hasChild">{{ data.value }}</div>
                    <div v-else></div>
                </template>
                <template #cell(medp_cost)="data">
                    <b-form-input v-if="!data.item.hasChild && variantAttribute"
                                  class="text-right"
                                  :value="data.item.medp_cost"
                                  @change="v => data.item.medp_cost = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'medp_cost', data.item.medp_cost, 2)">
                    </b-form-input>
                    <div v-else-if="!data.item.hasChild">{{ data.value }}</div>
                    <div v-else></div>
                </template>
                <template #cell(bed_amount)="data">
                    <b-form-input v-if="!data.item.hasChild && variantAttribute"
                                  class="text-right"
                                  :value="data.item.bed_amount"
                                  @change="v => data.item.bed_amount = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'bed_amount', data.item.bed_amount, 0)">
                    </b-form-input>
                    <div v-else-if="!data.item.hasChild">{{ data.value }}</div>
                    <div v-else></div>
                </template>
                <template #cell(price)="data">
                    <b-form-input v-if="!data.item.hasChild && variantAttribute"
                                  class="text-right"
                                  :value="data.item.price"
                                  @change="v => data.item.price = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'price', data.item.price, 2)">
                    </b-form-input>
                    <div v-else-if="!data.item.hasChild">{{ data.value }}</div>
                    <div v-else></div>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ isNaN(data.value) ? 0 : $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="openModalRowFilesByRowId(data.item.id, false)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <b-dropdown v-if="variantAttribute" id="dropdown-dropleft" dropleft class="more">
                        <template v-slot:button-content>
                            <i class="icon icon-more"></i>
                        </template>
                        <template>
                            <b-dropdown-item @click="openModalRowFilesByRowId(data.item.id, true)">
                                Добавить файл(ы)
                            </b-dropdown-item>
                            <b-dropdown-item v-if="variantAttribute" @click="deleteItem('Очистить запись?', data.item, data.index)">
                                Удалить
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </template>
                <template #bottom-row="data">
                    <td class="text-left" colspan="6">ИТОГО</td>
                    <td class="text-right">{{ isNaN(total) ? 0 : $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-attach ref="modalAttach"
                      :row-files-input="row_files"
                      :header="header"
                      :is-add="isAdd"
                      :variant-attribute="variantAttribute"
                      @toggleIsAdd="toggleIsAdd($event)"
                      @fileUpload="fileUpload"/>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
// import BudgetAttachFile from "./components/budget-attach-file";
import ModalAttach from '@/modules/budget-request/components/modal-attach.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form01_149',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, ModalAttach, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-149',
                name_ru: 'Расчет расходов на приобретение мягкого инвентаря в учреждениях здравоохранения',
                name_kk: 'Денсаулық сақтау мекемелерiндегi жұмсақ мүкәммал сатып алуға арналған шығыстарды есептеу'
            },
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'name_ru',
                    label: 'Наименование отделений (отдельных организаций)'
                },
                {
                    key: 'medp_count',
                    label: 'Число врачебных должностей'
                },
                {
                    key: 'medp_cost',
                    label: 'Стоимость нормы на 1 врач. должность в год, тенге'
                },
                {
                    key: 'bed_amount',
                    label: 'Кол-во коек'
                },
                {
                    key: 'price',
                    label: 'Стоимость нормы на 1 койку в год, в тенге'
                },
                {
                    key: 'total',
                    label: 'Сумма расходов на мягкий инвентарь (тыс.тенге) ((гр.2 х гр.3)+(гр.4 х гр.5))/1000'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dict: [],
            files: null,
            load: true,
            openDisabled: false,
            isLoad: false,

            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
        };
    },
    async mounted() {
        await this.loadDict();
    },
    methods: {
        openModalRowFilesByRowId(rowId, isAdd) {
            this.row_files = this.budgetForm.find(b => b.id === rowId)['row_files'];
            this.$refs.modalAttach.openModalRowAddFilesByRowId(rowId);
            this.rowId = rowId;
            this.isAdd = isAdd;
        },
        toggleIsAdd(return_object) {
            const curBudgetForm = this.budgetForm.find(b => b.id === this.rowId)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.isAdd = return_object['isAdd'];
        },
        fileUpload(rowId) {
            this.$refs.fileUpdown.openModalFileUpload();
            this.$refs.fileUpdown.setRowId(rowId);
        },
        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                let num_attach_files = 0;
                item['row_files'] = [...item['row_files'], ...files];
                item['row_files'].forEach((file) => {
                    if (file.row_id === item['id']) {
                        num_attach_files += 1;
                    }
                });
                item['files'] = num_attach_files;
            });
            this.$refs.modalAttach.addNewFiles(files);
        },
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                item['row_files'].forEach((file, index) => {
                    if (file.file_id === fileId) {
                        item['row_files'].splice(index, 1);
                    }
                });
                item.files = item['row_files'].filter(i => i.row_id !== null).length;
            });
            this.$refs.modalAttach.delFile(fileId);
        },

        changeValue(item, value) {
            this.$set(item, 'unit', value.unit);
        },

        async delete(item) {
            this.$set(item, 'form', this.form.code);
            try {
                this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'total'));
                const response = await fetch('/api-py/delete-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header), {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', 'Сообщение', 'Запись удалена');
                    // await this.saveTotal();
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных');
            }
        }, // удаление данных

        deleteItem(msg, row = false) {
            if (!this.variantAttribute || this.isLoad 
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.$bvModal.msgBoxConfirm(
                msg,
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            this.itemUpdate(row);
                        } else {
                            this.budgetForm.forEach(item => {
                                if (item.itemToDelete) {
                                    this.itemUpdate(item);
                                }
                            });
                        }
                        this.prepareForSave();
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления записи', error.toString());
                });
        }, // очистить значения строки

        async itemUpdate(item) {
            this.$set(item, 'medp_count', 0);
            this.$set(item, 'medp_cost', 0.0);
            this.$set(item, 'bed_amount', 0);
            this.$set(item, 'price', 0.0);

            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса itemUpdate', error.toString());
            }
            this.load = false;
        },

        downloadRep() {
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Форма' + this.form.code.replace('-', '_') + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        },

        getItem(code, list) {
            for (const item of list) {
                if (item.value.code === code) {
                    return item.value;
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            const that = this;

            that.load = true;
            that.budgetForm.splice(0);
            const hasCildId = that.dict.reduce((acc, itm) => {
                    if (itm.par_id !== null && !acc.includes(itm.par_id)) {
                        acc.push(itm.par_id)
                    }
                    return acc
                }, []);
            for (const item of that.dict) {
                const dataItem = {
                    id: this.newRowStartId,
                    id_dict: item.id,
                    par_id: item.par_id,
                    code: item.code,
                    name_ru: item.name_ru,
                    medp_count: 0,
                    medp_cost: 0.00,
                    bed_amount: 0,
                    price: 0.00,
                    hasChild: hasCildId.includes(item.id)
                };
                this.newRowStartId--;

                this.$set(dataItem, 'files', 0);

                try {
                    this.load = true;
                    const response = await fetch(`/api-py/get-new-row-files-form/${dataItem.id}/${JSON.stringify(this.header)}`);
                    const emptyRowFiles = await response.json();
                    this.$set(dataItem, 'row_files', emptyRowFiles);
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса itemUpdate', error.toString());
                }

                if (item.id === 14 || item.id === 17) {
                    Object.defineProperty(dataItem, 'total', {
                        get: function () {
                            return that.reSum(dataItem, 'total');
                        }
                    });
                } else {
                    Object.defineProperty(dataItem, 'total', {
                        get: function () {
                            const total = ((dataItem.medp_count * dataItem.medp_cost) + (dataItem.bed_amount * dataItem.price)) / 1000;
                            return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                        }
                    });
                }
                that.budgetForm.push(dataItem);
            }
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(that.header));
                values = await response.json();
                if (response.status === 200) {
                    if (values.length === 0) {
                        that.load = false;
                        return;
                    }
                } else {
                    this.budgetForm.splice(0);
                    this.makeToast('danger', 'Ошибка запроса loadDatas()', `Error code: ${response.status}`);
                }
            } catch (error) {
                this.budgetForm.splice(0);
                that.makeToast('danger', 'Ошибка запроса loadDatas()', error.toString());
            }

            await values.forEach(val => {
                const itemExist = that.budgetForm.filter(function (item) {
                    if (item.code === val.ward_type) {
                        return item;
                    }
                });

                if (itemExist.length > 0) {
                    itemExist[0].id = val.id;
                    itemExist[0].medp_count = val.medp_count;
                    itemExist[0].medp_cost = val.medp_cost;
                    itemExist[0].bed_amount = val.bed_amount;
                    itemExist[0].price = val.price;
                    itemExist[0].files = val.files;
                    itemExist[0].row_files = val.row_files;
                }
            });
            that.load = false;
        },

        async loadDict() {
            try {
                const response = await fetch('/api-py/dictionary/ward_types/');
                this.dict = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDict()', error.toString());
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        noAbc: function (event) {
            // const regex = new RegExp('^-?[0-9]+$');\.?
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит только цифры

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        prepareForSave() {
            const values = [];
            for (const row of this.budgetForm) {
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'ward_type', row.code);
                this.$set(item, 'medp_count', parseInt(row.medp_count));
                this.$set(item, 'medp_cost', parseFloat(row.medp_cost));
                this.$set(item, 'bed_amount', parseInt(row.bed_amount));
                this.$set(item, 'price', parseFloat(row.price));
                this.$set(item, 'row_files', row.row_files);

                values.push(item);
            }
            if (values.length > 0 && this.variantAttribute) {
                this.save(values);
            } else {
                this.makeToast('warning', 'Сообщение', 'Данные не сохранены');
            }
        },
        reSum(parent, field) {
            let sum = 0;
            this.budgetForm.forEach(row => {
                if (row.par_id === parent.id_dict) {
                    sum += parseFloat(row[field]);
                }
            });
            return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
        }, // пересчет суммы
        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        async save(values) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.total);
                const response = await fetch('/api-py/save_brform01_149/' + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // await this.saveTotal();
                    if (this.files.length === 0) {
                        this.makeToast('danger', 'Предупреждение', 'Внимание! Отсутствуют необходимые документы. Пожалуйста, прикрепите недостающие файлы.');
                    }
                    this.selectAll = false;
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    await this.loadDatas();
                } else {
                    this.selectAll = false;
                    await this.loadDatas();
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async saveTotal() {
            this.$set(this.header, 'value', this.total);
            try {
                const response = await fetch('/api-py/save-budget-request-total/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // this.makeToast('success', 'Сообщение', 'Данные сохранены');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения итога');
            }
        } // сохранение итога
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (row.par_id === null) {
                    sum += parseFloat(row.total);
                }
            }
            return Math.ceil(sum);
        }
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
</style>